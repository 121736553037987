const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/salaries",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    forReport({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/for-report?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    forSpendingStaffers({ dispatch, state, rootState }, options) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/for-spending-staffers?${serialize(options)}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    editFine({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/edit-fine`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    removeFine({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-fine`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    remove({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    confirm({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/confirm`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    edit({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/edit`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addBonus({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-bonus`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    editBonus({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/edit-bonus`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addFine({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-fine`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    removeBonus({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-bonus`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addPrePayment({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-pre-payment`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    moveSalaryBranch({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/move-salary-branch`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    setSalaryPayMethod({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/set-salary-pay-method`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
