const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/lessons-schedules",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    groups({ dispatch, state, rootState }, options = {}) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    teachers({ dispatch, state, rootState }, options = {}) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sciences({ dispatch, state, rootState }, options = {}) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/sciences?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    save({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    notifyParents({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/notify-parents`,
          method: "POST",
          data: {},
        },
        { root: true }
      );
    },
  },
};
